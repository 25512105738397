import { http } from '@/http/axios.js'


export function listAgentAPI(data) {
  return http({
    url: '/api/manage/agent/list',
    method: 'POST',
    data
  })
}


export function listAPI(data) {
  return http({
    url: '/api/manage/agent/employee/list',
    method: 'POST',
    data
  })
}

export function addAPI(data) {
  return http({
    url: '/api/manage/agent/employee/add',
    method: 'POST',
    data
  })
}


export function editAPI(data) {
  return http({
    url: '/api/manage/agent/employee/edit',
    method: 'POST',
    data
  })
}


export function delAPI(data) {
  return http({
    url: '/api/manage/agent/employee/del',
    method: 'POST',
    data
  })
}



export function detailAPI(data) {
  return http({
    url: '/api/manage/agent/employee/detail',
    method: 'POST',
    data
  })
}



